<script>
	import { MaterialApp, Button } from 'svelte-materialify';
	function copy() {
		navigator.clipboard.writeText("¯\\_(ツ)_/¯")
			.then(() => {
        //alert("¯\\_(ツ)_/¯");
      })
      .catch((err) => {
        alert(err);
      });
	}
</script>

<svelte:head>
	<title>Copy Shruggie</title>
	<meta name="robots" content="all" />
	<meta name="description" content="A web app to allow for easy copying and pasting of the ASCII shrug.">
	<html lang="en" />
	<!-- Global site tag (gtag.js) - Google Analytics -->
	<script async src="https://www.googletagmanager.com/gtag/js?id=G-RLW1DQQSXK"></script>
	<script>
  		window.dataLayer = window.dataLayer || [];
  		function gtag(){dataLayer.push(arguments);}
  		gtag('js', new Date());

  		gtag('config', 'G-RLW1DQQSXK');
	</script>
</svelte:head>

<main>
	<MaterialApp>
		<Button size="x-large" on:click={copy}>¯\_(ツ)_/¯ --> Clipboard</Button>
	</MaterialApp>
	  
	  

</main>

<style>
main {
	display: flex;
	padding-top: 100px;
	justify-content: center;
	align-items: center;
  }
</style>